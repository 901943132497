// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-jsx": () => import("/opt/build/repo/src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("/opt/build/repo/src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-campaigns-js": () => import("/opt/build/repo/src/pages/work/campaigns.js" /* webpackChunkName: "component---src-pages-work-campaigns-js" */),
  "component---src-pages-work-clients-index-js": () => import("/opt/build/repo/src/pages/work/clients-index.js" /* webpackChunkName: "component---src-pages-work-clients-index-js" */),
  "component---src-pages-work-editorial-js": () => import("/opt/build/repo/src/pages/work/editorial.js" /* webpackChunkName: "component---src-pages-work-editorial-js" */),
  "component---src-pages-work-index-js": () => import("/opt/build/repo/src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-motion-js": () => import("/opt/build/repo/src/pages/work/motion.js" /* webpackChunkName: "component---src-pages-work-motion-js" */)
}

